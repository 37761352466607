.hero {
  position: relative;
  overflow: hidden;
  height: 100vh; /* Full viewport height */
  display: flex; /* Use flexbox for alignment */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  text-align: center; /* Center text */
}

.hero .bg-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover; /* Ensure the image covers the entire section */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent repeating of the image */
  z-index: -1; /* Make sure the image is behind the text */
}

.hero h1 {
  position: relative; /* Ensure text is above the background */
  z-index: 1; /* Ensure the text is above the image */
  color: white; /* Text color */
  font-size: 4rem; /* Default font size */
  margin: 0; /* Remove default margins */
  padding: 0; /* Remove default padding */
  transition: opacity 1s ease-in-out; /* Smooth transition for text */
  opacity: 0;
  animation: fadeIn 1s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

/* Media queries for smaller screens */
@media (max-width: 768px) {
  .hero h1 {
    font-size: 3rem; /* Smaller font size for tablets */
    padding: 0 2rem; /* Add some padding for better readability */
  }
}

@media (max-width: 480px) {
  .hero h1 {
    font-size: 2rem; /* Smaller font size for mobile phones */
    padding: 0 1rem; /* Add padding to avoid text touching edges */
  }
  .content-why{
    display: block !important;
  }
}

.why-choose-us {
  text-align: center;
  margin-top: 100px; /* Add space below the heading */
}


.content-why {
  margin-top: 40px;
}

.flex {
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.icon {
  font-size: x-large;
}

.fixed-height {
  height: 100px; /* Set the desired height for all images */
  width: 100%; /* Ensure the image fills the container width */
}
