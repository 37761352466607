.image1 {
    display: flex;
}

@media (max-width: 480px) {
    .images1 {
        display: block;
    }
    .sm-images1 {
        display: flex;
        margin-top: 10px;
        flex-wrap: wrap;
    }
    .sm-images1 img{
        width: 150px;
    }
}